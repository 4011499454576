.queue-dashboard {
	width: 100%;
	padding-top: 2rem;
	padding-right: 2rem;
	padding-left: 0;
	padding-bottom: 0;
	margin-left: auto;
	margin-right: auto;
	color: $gray-dark;
	position: relative;
	display: block;

	&__table {
		@include clearfix();
		text-align: center;
		display: block;
		padding-left: 0.5rem;
		width: 100%;
    	content: "";

		&_category {
			width: 15%;
			text-align: right;
			padding-right: 0.5rem;
			left: 8%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			float: left;
			.queue-title {
				height: 1.75rem;
			}
		}
		&_queue {
			@include clearfix();
			padding-left: 1rem;
			width:70%;
			float: left;
			overflow-x: auto;
			display: inline-block;
			content: "";
			padding-bottom: 1rem;
			.queue-details {
				min-width: 100%;
				display: block;
				white-space: nowrap;

				&__queue {
					border-left: 1px solid;
					border-top: 1px solid;				
					width: 12rem;
					display: inline-block;
					text-align: center;
					height: 1.75rem;
				}
				&__queue-last-row{
					border-right: 1px solid;
				}
			}
			.supplier-details {
				min-width: 100%;
				display: block;
				white-space: nowrap;

				&__supplier {
					border-top: 1px solid;				
					width: 12rem;
					display: inline-flex;;
					text-align: center;
					height: 1.75rem;
					&_title {
							word-break: break-all;
							text-overflow: ellipsis;
							text-wrap: wrap;
						}
					}
			}
		}

		&_title {
			transform: rotate(-0.25turn);
			left: 0;
			margin-top: 9.375rem;;
			width: 5%;
			float: left;
		}
	}


	&__label-bottom {
		padding-bottom: 1.5rem;
		color: $gray-dark;
		text-align: center;		
    	border-top: 1px solid $gray-lighter;
    	margin: 0 10rem;
	}
}