.pos-toggle-button{
	display: inline-block;
    position: relative;	
	
	&__enabled-bar{
	    margin: 4px 0;
	    width: 32px;
	    height: 14px;
	    background-color: $brand-color-13;
	    display: inline-block;
	    border-radius: 7px; 
	  
		  				
	}
	&__disabled-bar{
	    margin: 4px 0;
	    width: 32px;
	    height: 14px;
	    background-color: $brand-color-5;
	    display: inline-block;
	    border-radius: 7px; 
		  				
	}
	&__enabled-state{
	    width: 18px;
	    height: 18px;
	    background-color: $brand-color-12;
	    display: inline-block;
	    border-radius: 50%;
	    position: absolute;
	    right: 0;
	    top: 2px;
    
	}
	&__disabled-state{
	    width: 18px;
	    height: 18px;
	    background-color: $brand-color-10;
	    display: inline-block;
	    border-radius: 50%;
	    position: absolute;
	    left: 0;
	    top: 2px;
    
		  
	}
}