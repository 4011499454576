html {
	scroll-behavior: smooth;
}
body {
  text-align: left;
  // font-size: 12px;
  // background-color: $body-color;
  font-weight: $font-weight-base;
  
  .wrapper {
  	min-width: $xs-width;
  	@media only screen and (min-width: $sm-screen-size) {
  		min-width: $sm-width;
  	}
  	@media only screen and (min-width: $md-screen-size) {
  		min-width: $md-width;
  	}
  	@media only screen and (min-width: $lg-screen-size) {
  		min-width: $lg-width;
  	}
  }
}

.header {
  width: 100%;
}
ul, ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mandatory-field{
	position:relative;
	&:after {
		content: "\f069";
	    font-family: FontAwesome;
	    position:absolute;
	    top:0;
	    font-size: 0.4rem;
	    color: #AB0635;
	    padding-left: 2px;
    	padding-bottom: 0.625rem;
	}
}

.modal-open {
	overflow: scroll;
}