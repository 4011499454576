.pos-header {
	@include create-main-wrapper;
	&_wrapper {
      @include clearfix;
      display: flex;
      align-items: center;
    }
	
	background-color: $brand-color-2;
	min-height: 30px;

	&__title {
		display: flex;
		text-transform: uppercase;
	    text-decoration: none;
	    margin: 0;
	    padding: 5px;
    	margin-right: auto;
	}
	
	&__logo {
		float: left;
		
		img {
			max-height: 55px;
			margin-right: 0.25rem;
		}
	}
	&__name{
	    display: inline-block;
	    vertical-align: bottom;
	    font-size: 1.25rem;
        margin-top: 0.375rem;
        color: $brand-color-11;
		align-self: center;
	}
	&__navigation{
		display: flex;
		&_auth-nav {
			display: flex;
            flex-direction: column;
            align-items: flex-end;

            @include make-row;
            .auth-nav {
                &__logout {
                    text-transform: uppercase;
                    color: #fff;
                    text-decoration: none;
                    margin: 0;
                    padding: 5px;
                }
                &__user-name {
                    text-decoration: none;
                    color: $brand-color-11;
                    margin: 0;
                    padding-top: 0.75rem;
                }
                &__balance{
                    @include make-row;
				    display: flex;
				    flex-direction: column;
    				align-items: flex-end;
                    &_data {
                        color: $brand-color-11;
						.selected-client-info {
							display: flex;
							margin-right: 0;
	                        &__refresh-icon:hover {
	                            cursor:pointer;
	                        }
						}
						.daily-cash-limit {
							display: flex;
							&__balance {
								display: flex;
								flex-direction: column;
    							align-items: flex-end;
								&_air-limit {
									margin-right: 0;
								}
							}
	                        &__refresh-icon {
		    					align-self: flex-end;
							}

	                        &__refresh-icon:hover {
	                            cursor:pointer;
	                        }
						}
                    }
                }
            }
        }
    }
	
	.login__popover {
		&_user-id-arrow{
			@include make-triangle(bottom, 5px,$brand-color-11);
			float: right;
	    	margin-top: .5rem;
	    	margin-left: .15rem;
		}
	}

	.popover{
		.login__account{
			width:150px;
			@include clearfix;
			a{
				padding:15px 15px;
				text-decoration: none;
				background-color:transparent;
			}
		}
		.login__account_links{
			@include clearfix;
			width:100%;
			&:hover,
			&:focus
			{
				background-color:$gray-lighter;
			}
		}
		.links__icon{
			margin-right:5px;
		}	
	}
}
