.pos-queue-management-remote-filter {
    float: right;
    margin-right: 12rem;
    position: absolute;
    top: -2.5rem;
    right: 0rem;
	
   	&__reset-button {
	   float: right;
       margin: 0 10px;
	    
		&_reset-all {
			@include make-button($brand-color-11, $brand-color-2, capitalize);
			@include button-size(10px ,10px, 15px, 0, 4px, 100%);
		    height: 1.875rem;
		}
		&_reset-all:disabled:hover{
		    cursor: not-allowed;
		}		    
   	}
   	
   	&__add-button {
   		float: right;
   		margin-left: 10px;
		>button {
			@include make-button($brand-color-11, $brand-color-2, capitalize);
			@include button-size(10px ,10px, 15px, 0, 4px, 100%);
			min-width: 180px;
		    height: 1.875rem;
		}
	}
	
	
	&__form {
		> form {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
		}
		
		padding: $base-padding;
		border: 10px solid $brand-color-3;
		margin-bottom: 1rem;
	    display: table;
    	width: 100%;
    	
    	.remote-date-container{
			@include clearfix;
		}
		
		label{
		    color: $brand-color-2;
		    margin-bottom: 0px;
		}
			
		&_container, &_dropdown{
			@include clearfix;
	   		padding-left: 0rem;
    		padding-top: 0rem;
	   		padding-bottom: calc($base-padding/2);
			width: 85%;
				>label{
					width: 100%;
					font-size: 15px;
				}
		    .check-box-label {
				width: 50%;
			}
		}
		
		&_dropdown{
			.multi-select {
				.dynamic-multi-select__label {
					color: $brand-color-2;
					font-weight: bold;
				}
			}
			>select{
				float: left;
		    	// width: 85%;
		    	border-radius: 0px; 
		    	color: inherit;
		    	padding: calc($base-padding/7) ;
			}
		}
			
		&_date-dropdown{
			@include clearfix;
	   		padding-left: 0rem;
    		padding-top: 0rem;
	   		padding-bottom: calc($base-padding/2); 
			select{
				color: inherit;
				width: 85%;
				border-radius: 0;
				padding: calc($base-padding/7) ;
				color: inherit;
			}
			.pos-trans-manager-list {
				&__remote-filter{
					&_date-range { 
						padding-right: 3.5rem;
					}
				}
			}
		}
		
		&_supplier-dropdown{
			@include clearfix;
	   		padding-left: 0;
    		padding-top: 0;
			padding-bottom: calc($base-padding/2);
			float: none; 
			select{
				color: inherit;
				width: 85%;
				border-radius: 0;
				padding: calc($base-padding/7) ;
			}
		}
			
		&_container{
			.container{
				&__text{
					padding: calc($base-padding/7) ;
				    width: 100%;
				    border: 1px solid $light-grey;
				    height:2.125rem;
				}
				&__date-field{
				    width: 85%;
				}
			}	
   		}
   		
   		&_remote-search-btn {
   			@include clearfix;
		    grid-column: 1/4;
		    padding-left: 1.5rem;
			>button{
				@include  make-button($light, $brand-color-1 , none);
				@include  button-size(0, 0, 1.375rem, inherit, 0, 28%);
				border-radius: 8px;
				margin-right: 6%;
    			float: right;
    			&:hover {
					background-color: darken($brand-color-1, 10);
				}
				&:disabled {
   		   			cursor: not-allowed;
   		   			opacity: 0.65;
	       			box-shadow: none;
        		}
			}
   		}
   		
   		.autosuggestion{
			position: relative;
			&__item {
			    &:hover,
			    &:focus {
			      cursor: pointer;
			      background-color: $brand-color-3;
			    }
			    &_title {
			      color: inherit;
			      border-bottom: solid 1px $gray-lighter;
			      margin-bottom: 0rem;
			      font-size: inherit;
			      padding-left: 15px;
			      padding-right: 15px;
			      padding-top: 10px;
			      > .autosuggest-code {
			        color: $brand-color-5;
			      }
			      > .autosuggest-country {
			        color: $brand-color-5;
			      }
			   }
			}
			&__textbox{
				display: initial; 
		    	width: 85%;
		    	border-radius: 0px;
		    	padding: calc($base-padding/7) ;
		    	color: inherit; 
			    border: 1px solid $light-grey;
			}
			>label{
				width: 100%;
				font-size: inherit;
			}
		}
		.itemcontainer{
			box-shadow: 1px 7px 50px 2px rgba(168,166,168,.5);
			max-height: 500px;
			overflow-y: hidden;
			z-index: 5;
			position: absolute;
			background-color: $light;
			border-top: 1px solid $brand-color-2;
			border-right: 1px solid $gray-lighter;
			border-bottom: 1px solid $gray-lighter;
			border-left: 1px solid $gray-lighter;
			margin-top: -1px;
			left: 0;
			right: 0;
			width: 85%;
		}
		.selecteditem {
			background-color: $brand-color-3;
		}
		.input-group{
			>input{
			 padding: calc($base-padding/7) ;
			}
		}
		&_save-checkbox {
			padding-top: 1.6rem;
		}
	}
	
	.triangle , .remote-triangle{
	    position: relative;
	    top: 0.125rem;
	    margin-left: 0.2rem;
	    float: left;	
		@include make-triangle(bottom, 0.4375rem, $light);
	}
	
	.remote-triangle-active{
	    position: relative;
	    top: -0.5rem;
	    margin-left: 0.2rem;
	    float:left;	
		@include make-triangle(top, 0.4375rem, $light);
	}
	.triangle-active {
	    bottom: 0.8125rem;	
	    position: relative;
		@include make-triangle(top, 0.4375rem, $brand-color-2);
	}
	
	.remote-search-btn{
		float: left;
    	padding-right: 0.3rem;
    	line-height: 0.5;
	}
	
	.selected-filter-count{
		float: left;
	    width: 1.25rem;
	    height: 1.25rem;
	    background-color: $light;
	    color: $gray;
	    border-radius: 0.9375rem;
	    border: 0.0625rem solid $gray;
	    font-family: $font-family-number;
	    font-size: .75rem;
	    padding-top: 0.4rem;
	    padding-right: 0.1rem;
	    margin-top: -0.3rem;
	    line-height: 0.5;
	}
}