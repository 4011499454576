.required {
		position:relative;
		&:after{
			content: "\f069";
		    font-family: FontAwesome;
		    position: absolute;
		    top: 0px;
		    font-size: 0.4rem;
		    color: $brand-color-1;
		    padding-left: 2px;
		    padding-bottom: 0.625rem;
	
		}
	}
	
.pos-error-message {
	position: fixed;
    top: 50px;
    right: 20px;
    width: 500px;
    z-index: 50;
    .ng-toast__list {
    	margin-bottom: 0;
    }
}
.error-text-message{
	color: $brand-color-1;
}
