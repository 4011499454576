.pos-interstitial {
	&__header {
      height: 30%;
      text-align: center;
      &_label {
        color: $brand-color-2;
        text-transform: capitalize;
        padding-bottom: 1rem;
        padding-top: 0.5rem;
      }
      .client-logo > img {
        max-width: 150px;
      }
    }
    &__footer {
      &_text {
        color: $brand-color-2;
        text-align: center;
        padding-top: 1rem;
      }
    }
	&__interstitial-progress-container{
		box-shadow: $row-shadow-color;
		height:20%;
		background-color: $light;
		&_progress{
		padding-top: 2.2rem;
		}
	}
}
