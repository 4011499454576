.pos-system-user-list{

	&_card {
		padding: 0;
	}
	
	&__header {
		width : 100%;
		display : table;
    	@include clearfix;
		@include make-header-title;
		@include make-action-bar;	
		
		&_action-bar {
			padding-bottom: .625rem;
		}	
	}
	
	@include make-user-details-container;
	
	.traveler{
		@include make-personal-details-container;
		
		&__contact-details {
			&_mobile, &_designation-details{
				width: 100%;
			}
			
			&_mobile {
				margin-bottom: 0.5rem;
			}
		}
		
		&__personal-details_user {
			 .user-controls {
		    	&__container_edit {
		    		position: relative;
		    		top: 4px;
		    	}
		    }
		}
	}
	
	.designation-details{
		&__job-title , &__branch-name{
			width : 50%;
			float: left;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	
	.mobile-details {
		@include create-icon-container(1.75rem, -0.25rem, -0.25rem,  0.375rem, 1.875rem, 0.4375rem);
	}
	
	.branch-name-details {
		@include create-icon-container(1rem, 0.1875rem, 0, 0.25rem, 1.875rem, 0.25rem);
	}
	.job-title-details {
		@include create-icon-container(1.05rem, 0.1875rem, 0, 0.35rem, 1.875rem, 0.35rem);
	}
	
	&__wrapper {
		@include clearfix;
    }
    
    .personal-details-username {
    	float: left;
    	
		@include create-icon-container(1rem, 0, 0, 0.3125rem, 1.875rem, 0px);
    }
    
    .user-controls {
    	width: 17%;
    	
    	&__edit {
    		position: relative;
    		top: 4px;
    	}
    }
	
	&__loader{
		@include create-loader-background;
		margin: 0;
	}
	&__status-msgs{
	    text-align: center;
       	padding : 1rem;
        font-size: $font-size-h3;
	}
}