.pos-reset-password-form,
.pos-login-form {

	@include create-main-wrapper;
    position: relative;
	
	&_wrapper {
		@include clearfix;
		margin: auto;
	    width: 400px;
        height: 750px;
    }
    
    &__title {
    	font-size: 2rem;
    	text-align:center;
    }
    
    &__body {
    	margin: auto;
    	max-width: 600px;
    	text-align:center;
    }


	@include create-row-card;	
	
	&_card{
		box-shadow: $row-shadow-color;
		margin-top: 5rem;
	    padding: 10px 3rem;
        background-color: $brand-color-4;
	};



	&__header{
    	text-transform: none;
   		 color: $brand-color-2;
   		 //border-bottom: 1px solid $brand-color-2;
   		 margin-bottom: calc($base-padding/2);
   		 padding: calc($base-padding/6) calc($base-padding/2);
   		 padding-left: 0px;
	     text-align: center;
   		 
   		 &_text{
		    font-size: 2rem;
	     	font-weight: 700;
   		 }
   		 &_close{
			float: right;
    		font-size: 1.25rem;
    		font-weight: bold;
    		cursor:pointer;
		 }
   		 	
	}
	&__content{
		margin-bottom: calc($base-padding/2);
	}	
	    
}
.reset-password__page,
.login__page {

  @include create-row-card;
  @include create-row-card-header;
  @include create-card-content;

  &_card {
    box-shadow: $row-shadow-color;
    &_header {
      text-transform: uppercase;
      color: $brand-color-2;
      border-bottom: 1px solid $brand-color-2;
      margin-bottom: calc($base-margin/2);
    }
    .header_text {
      margin-right: $base-margin;
    }

  }
  &_form {
    max-width: 400px;
    margin: 0 auto;
  }
  &_input_wrapper {
    margin-bottom: calc($base-margin/2);
    
  label {
    width: 100%;
    margin-bottom: 0;
    color: $brand-color-2;
    font-size: 0.875rem;
  }
  input[type="text"],
  input[type="email"],
  input[type="password"] {
    width: 85%;
    padding: 0.5rem 1rem;
    background: none;
    font-size: 0.875rem;
    vertical-align: middle;
    line-height: normal;
    background-color: $light;
    border: 1px solid;
    border-color: $input-border-color;
    max-height: 34px;
  }     
    
  }
  &_button {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    background-color: $brand-color-2;
    border: none;
    line-height: inherit;
    border-radius: 0;
    background-image: none;
    width: 100%;
    color: $light;
    font-weight: 700;
    &:hover {
      background-color: darken($brand-color-2, 20);
    }
    margin-bottom: 0.63rem;
	&:disabled {
	  background-color: lighten($brand-color-2, 20);
	  &:hover {
	    background-color: lighten($brand-color-2, 20);
	  }
	  cursor: not-allowed;
	}    
  }
  &_oauth-list{
    margin-top: 0.625rem;
  }
	&_links{
		display: flex;
	    justify-content: space-between;

    	@include clearfix;
    	a {
	    	text-transform: none;
	        color:$brand-color-2;
            font-weight: 100;
	        text-decoration: none;
	        &:hover,
	        &:focus {
	          text-decoration: underline;
	          color:darken($brand-color-2, 10);
	        }
	    }
    }
    
    
	&_input-group {
		@include clearfix;
		width: 100%;
		.fa-user, .fa-lock, .fa-envelope, .fa-building{
			width: 15%;
		}		
	}
	
	&_input-group-addon {
		white-space: nowrap;
	    vertical-align: middle;
	    padding: 6px 10px;
	    font-size: 20px;
	    font-weight: 400;
	    line-height: 1;
	    text-align: center;
	    border: 1px solid darken($brand-color-11, 30);
	    float: left;
    }      
      
   &_registration-message{
   		color:$brand-color-2;
   }
   
   &_register-now{
   		@include make-button($light, $brand-color-1,capitalize,$light,darken($brand-color-1, 20));
   		padding-top: 0.3125rem;
    	padding-bottom: 0.3125rem;
   		width: 100%;
    	font-weight: 700;
   	}
    &_button-separator{
        margin-top:5px;
        .login__page_button-separator-label {
            position: relative;
            z-index: 1;
            text-align: center;
            &:before {
                border-top: 1px solid $brand-color-2;
                content:"";
                margin: 0 auto; 
                position: absolute; 
                top: 50%; left: 0; right: 0; bottom: 0;
                width: 100%;
                z-index: -1;
            }
            span { 
                background: $brand-color-4; 
                padding: 0 15px; 
            }
        }
    }
}

.wrapper{
	&__error{
		color: rgba(192,0,0,1);
		font-weight:100;
		font-size: $font-size-h5;
	}
	&__password-help-text{
		font-size: 0.75rem;
		color: $brand-color-2;
	}
}

