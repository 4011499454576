.pos-dashboard {
	@include create-main-wrapper;
    margin-bottom: 1rem;
	
	&__card {
		box-shadow: $row-shadow-color;
		background-color: $brand-color-11;
		margin-bottom: 1rem;
		
		&_loader{
			@include create-loader-background;
			margin: 0;
		}

		&_calendar-graph {
			width : $sm-width;
			margin : 0 auto;
			rect {
		    	cursor: pointer;
			}
		}
		
		&_graph-title {
			font-size: $font-size-h4;
		    text-align: center;
		    padding: 0.25rem 0;
		    border-bottom: 1px solid #ccc;
		    margin: 0 10rem;
		}
		
		&_balance{
			display: flex;
			padding: 1rem;
			color: $brand-color-2;
			font-size: 1rem;
			.selected-client-info__refresh-icon {
			    margin-left: 1rem;
				&:hover{
					cursor:pointer;
				}			    
			}
		}
	}
	
	&__multiple-card {
		box-shadow: $row-shadow-color;
		background-color: $brand-color-11;
		margin-bottom: 1rem;
		@include clearfix;
		
		&_graph-title {
			font-size: $font-size-h4;
		    text-align: center;
		    padding: 0.25rem 0;
		    border-bottom: 1px solid #ccc;
		    margin: 0 10rem;
		}
		
		&_graph-data {
			width: 33%;
			@include clearfix;
			float:left;
		}
		
		&_loader{
			@include create-loader-background;
			margin: 0;
		}
	}
}