.pos-refund-pax-sector-details{
	@include create-main-wrapper;
	
	&__content{
		&_pax-info{
			padding : calc($base-padding/4) calc($base-padding/2);
		}
		&_sector-info{
			padding: 0.25rem 1rem;
		}
	}
	
	.sector-info{
		&__headers{
		
			@include clearfix;
			color: #303030;
			&_title{
	    		width: 20%;
			}
			&_airline-title{
	    		width: 15%;
			}
			&_travel-date-title{
	    		width: 25%;
			}
			&_title, &_airline-title,&_travel-date-title {
				padding: calc($base-padding/7);
				background-color:  #D3D3D3;
				float: left;
	    		text-align: center;
			}
		}
		
		&__details{
			@include clearfix;
			
			&_data{
	    		width: 20%;
			}
			&_data , &_airline-data ,&_travel-date-data{
				float: left;
	    		margin-top: 3px;
	    		text-align: center;
			}
			&_airline-data{
	    		width: 15%;
	    		text-align: inherit;
			}
			&_travel-date-data{
	    		width: 25%;
			}
			&_cash-earned {
				width: 2%;
    			float: left;
    			margin-left: -3rem;
    			margin-top: 3px;
			}
			.data{
					&__description, &__airline-description{
						width : 85%;
						margin-right: $base-margin;
					}
					&__airline-description{
						margin-right: 0;
					}
					&__checkbox{
						width : 15%;
						float: left;
					}
					&__image{
						max-height: 25px;
						max-width: 25px;
						margin-left: 20%;
					}
			}
		}
	}
	.fa{
		font-size: inherit;
	}
	
}