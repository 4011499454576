.pos-menu {

	@include create-main-wrapper;
    @include clearfix;
	
	&_wrapper {
      @include clearfix;
    }
	
	&__search-bar {
		@include make-row;
		float: left;
		width: 25%;
		.form-control{
			height:2rem;
		}
	}
	
	&__logo {
		float: left;
		padding: .9375rem .9375rem .9375rem 0;
		
		&-image {
			max-height: 48px
		}
	}
	
	&__nav{
		@include make-row;
		&_alert-warning {
  		 width: 55%;
  		}
		&_primary-nav, &_secondary-nav {
			float:left;
		}
	}
	
	&__nav-primary{
		@include make-row;
		background-color: $brand-color-11;
	    margin-top: 1px;
		&_nav{
			@include make-row;
			width:75%;
			float:left;
		}	    
	}
	
	&__nav-secondary{
		@include make-row;
		background-color: $brand-color-3;
	}

	.primary-nav, .secondary-nav{
		&__label{
 			display: block;
 			height: 2rem;
			padding: 5px 10px;
		    text-align: center;
			&_active{
			    background-color: $brand-color-3;
		        font-weight: bold;
		        color: $brand-color-15;
			}
		}
		
		&__corporate-menu {
 			width: 100%;
 			height: 2rem;
			padding: 5px 10px;
		    text-align: center;
			&_active{
			    background-color: $brand-color-3;
		        font-weight: bold;
		        color: $brand-color-15;
			}
		}
		
		&__blink{
		    animation: blinker 0.8s linear infinite;
            color: $brand-color-20;
            @keyframes blinker {  
            	50% { 
            		opacity: 0;
            	}
            }
		}
		
		&__extra{
		    display: block;
 			height: 2rem;
			padding: 5px 10px;
		    text-align: center;
		    background-color: $brand-color-18;
    		border-radius: 5px 5px 0 0 !important;
   			color: $brand-color-20 !important;
 			font-size: 12px !important;
   			margin-right: 2px !important;
    		margin-bottom: 0px !important;
    		padding: 6px 19px 5px !important;
    		margin-top: 0 !important;
		    &_active{
			    background-color: $brand-color-19;
		        font-weight: bold;
		       
			}
		}
		
	    .popover{
		    font-size:12px;
		}		
		
	}
	
	.secondary-nav{
		float:left;
		&__label:hover{
	    		border-bottom: 5px solid $brand-color-2;
	    	}		
	    &__label_active{
		        border-bottom: 5px solid $brand-color-2;
	    }
	}
	
	a{
		display:inline-block;
		text-decoration: none;
	}
	a:hover{
		color: $brand-color-15;
		text-decoration: none;
		font-weight: bold;
	}	
	
	a::after {
	    display:block;
	    content:attr(title);
	    font-weight:bold;
	    height:1px;
	    color:transparent;
	    overflow:hidden;
	    visibility:hidden;
	    margin-bottom:-1px;
	}	

}

