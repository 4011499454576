.pos-pricing-model-list {
	@include create-row-card;
	@include clearfix;
	
	&_card {
		box-shadow: $row-shadow-color;
		padding: 0.9375rem;
    	padding-top: 0;
	}
	
	&__wrapper {
		@include clearfix;
    }	
	
	.nav-tabs>li>a{
		font-size: $font-size-h5;
	}
	
	&_content{
		@include create-nav-tabs;
	}
	
	&__status-msgs{
	    text-align: center;
       	padding : 1rem;
        font-size: $font-size-h3;
	}
	
	&__header{
		@include make-row;
		@include make-header-title;
		@include make-action-bar;
		padding-bottom: calc($base-padding/3);
		display : table;
	    width: 100%;
	}		
	
	@include make-user-details-container;
	
	.pricing-model{
   	    @include make-row; 
   	    padding: calc($base-padding/3);

   	    &__header{
 			@include make-row; 
   	    	&_user-controls{
   	    		float:left;
 				width:40%; 
   	    	}

	   	    &_model{
			    font-size: 1.25rem;
   	    		float:left;
 				width:60%;
	   	    	
	   	    	.model-id__title{
	   	    		color: $brand-color-2;
	   	    	}
	   	    }   	    	
   	    }
   	    
 	   	&__customer-rating{
  	    	@include make-row;
  	    	font-size: 1rem;
			
			&_title{
				float:left;
			} 
			&_rule-list{
				float:right;
			} 
 	    }
   	    
   	    &__dates, &__transactions{
   	    	@include make-row;
   	    	font-size: 1rem;
   	    	
   	    	&_validity, &_pricing-model, &_type{
	    	   	float:left;
 				width:50%;
   	    	}
   	    	&_validity{
   	    		@include clearfix;
   	    		width: 100%;
   	    	}
   	    }
	}			
	

	.user-controls{
		&__container {
			float: right;
			
			&_activate{
				float: right;
				margin-top: 3px;
				margin-left: calc($base-margin/5);
				cursor: pointer;				
			}			
			&_edit, &_copy{
				cursor: pointer;
			}			
			&_customer-mapped{
				font-size: 18px;
				margin-left: calc($base-margin/11);
			}			
		}
		&_is-active{
		  display:inline-block;
		  position: relative;		
		}
		
	}	
	
	
	
}
