.guest-house-list{
	&__container{
		float: left; 
		width: 100%;
	}
	&__edit-icon{
			color:$brand-color-2;
		&:hover {
  			cursor: pointer;
 		}
	}
	&__transaction-list{
		color: $brand-color-13;
		&:hover {
  			cursor: pointer;
   		}
	}
	&__heading{
		color: $brand-color-2;
		text-align: center;
		font-size: 14px;
    	font-weight: 400;
	}
}

