.pnr-console-output {
    font-family: monospace;
    font-size: 1rem;
	&__command-responses {
		padding-bottom: 1rem;
		&_command {
		    font-weight: 700;
	        margin-top: 0.25rem;
		}
		&_response {
    		background-color: darken($brand-color-11, 5%);
			line-height: 1.15;
		    padding: 1rem;
		}
	}
}