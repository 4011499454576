.pos-trans-manager-details {
	&__header{
		@include make-row;
		text-transform: capitalize;
  		font-size: 1rem;
  		color: $brand-color-2;
		
		&_link{
			width:50%;
			float:left;
			&:hover{
				cursor: pointer;
			}	
		}
		&_title{
			width:50%;
			float:left;
		}
	}
	
	.booking__screen_card{
		width: 100%;
	}
	
	.link__action{
    	text-align: right;
		float: right;
	}
	
	.panel-heading{
		padding: 0;
		background-color: $brand-color-11;
	}
	.panel-body{
		padding: 0;
	}
	.panel-group .panel-heading+.panel-collapse>.panel-body{
	    border-top: none;
	}	
	.panel-group {
		.panel{
		    margin-bottom: 6px;
		}	
	}
	.panel-group{
		margin-bottom: 0; 
	}
	.customer__notes-description, .product-summary, .fare-rules__value, .booking-invoice__link{
		> a {
			color: $brand-color-1;
		}
	}
	.modal-content {
	    margin-top: 15rem;
	}	
	button{
		border:none;
	}
	label{
		color: $brand-color-2;
	}
}
.pos-app-content{
	.modal__content{
		padding:0;
		.panel-heading{
			padding:0;
		}
		.panel-body{
		    font-size: 12px;
		    padding: 0;
		}
	}
}