.pos-app {
	margin: auto;
    font-weight: 400;
}
.pos-auth-content{
	background: linear-gradient(rgba(29,46,16,0.6),rgba(29,48,16,0.6)), url(../images/background.jpg);
	background-size: cover;	
}

.pos-app-content{
    overflow-y: scroll;
}
