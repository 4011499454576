 @use "sass:math";
 @mixin create-nav-tabs_private{
 
 .nav {
      margin-left: math.div(-$base-margin,2);
      margin-right: math.div(-$base-margin,2);
      background-color: $brand-color-3;
    }
    .nav-tabs {
      border-bottom: none;
    }
    .nav-tabs > li > a {
      margin-right: 0;
      border: none;
      border-radius: 0;
      color: $brand-color-2;
      font-size: $font-size-h5;
    }
    .nav > li > a:hover,
    .nav > li > a:focus {
      background-color: $light;
    }
    .nav-tabs > li.active > a,
    .nav-tabs > li.active > a:hover,
    .nav-tabs > li.active > a:focus {
      color: $brand-color-2;
      background-color: $light;
      border: none;
      cursor: default;
    }
  }

@mixin  create-nav-tabs{ 
	@include create-nav-tabs_private; 
}