.pos-task-history{
	
	@include create-main-wrapper;
    @include create-row-card;
	&_wrapper {
		@include clearfix;
    }
    &_card{
		box-shadow: $row-shadow-color;
	}
	&_content{
        
        @include create-nav-tabs;
		padding-bottom: calc($base-padding/3);
    }
}