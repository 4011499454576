.pos-queue-management-row{
		@include create-row-card;
		@include create-card-content;
	    box-shadow: $row-shadow-color;
	    @include clearfix;
	    padding: 0 ;
    	margin-bottom: .9375rem;
    	background-color: $light;
    	color: $brand-color-22;
    	cursor: pointer;
    	
    	&__queue-header{
    		@include clearfix;
	   		width : 100%;
			padding: 0.3125rem 0.3125rem 0 0.3125rem;
			height: 2.5rem;
			&_queue-detail{
				float: left;
				width: 11%;
				text-align: center;
				min-height: 1px;
				padding-left: 0.3rem;
				padding-right: 0.3rem;
			}
			&_pnr {
				float: left;
				width: 11%;
				text-align: center;
				color: $brand-color-1;
				font-size: 1.125rem;
				min-height: 1px;
				padding-left: 0.3rem;
				padding-right: 0.3rem;
			}
	}
	
	&__details:nth-child(even) {
		background-color: $brand-color-11;
	}
	&__details:nth-child(odd) {
		background-color: $gray-lighter;
	}
	&__details {
		@include clearfix;
	    width: 100%;
	    font-size:0.875rem;
	    
	    &_details-container {
			@include clearfix;
	    	width: 100%;
	    	padding: 0.375rem;
	    }
	    
	    .details-container {
			&__queue-details{
				float: left;
		    	width: 15%;
		    	line-height: 1.5625rem;
    			min-height: 1.5625rem;
		    	
		    	.queue-no {
					color: $brand-color-2;
					float: left;
					padding-right: 1.3rem;
					padding-left: 1.3rem;
		    	}
		    	.queue-category {
		    		float: left;
		    	}
			}
			&__status {
				float: right;
		    	width: 15%;
		    	line-height: 1.5625rem;
				min-height: 1.5625rem;
				padding-left: 0.3rem;
				padding-right: 2.5rem;
    			text-align: center;
				.popover {
					word-break: break-word;
					}
				&_new {
					color: $queue-mgmt-status-new;
				}
				&_accepted {
					color: $queue-mgmt-status-accepted;
				}
				&_rejected {
					color: $queue-mgmt-status-rejected;
				}
				&_removed {
					color: $queue-mgmt-status-removed;
				}
				&_value {
					font-weight: 700;
				}
			}
	    }
	}
	&__checkbox {
          position: absolute;
          left: 3%;
     }
}