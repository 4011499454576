.customer-email-verification {
	@include clearfix;
    background-color: $brand-color-4;
    box-shadow: $row-shadow-color;
    margin : 0 auto;
    margin-top: 1rem;
    padding: 1rem;
    text-align: center;
    width : $sm-width;
	&__header {
        font-size: 2rem;
		font-weight: 700;
		color: $brand-color-2;
	}
	&__links {
		color: $brand-color-2;
		float: left;
        font-size: 1rem;
	}
    &__message {
        font-size: 1.5rem;
        &_unverified-icon {
            color: $brand-color-1;
        }
    }
}
