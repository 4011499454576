@mixin make-triangle_private($triangle, $size, $color){
  height: 0;
  width: 0;
  border: $size solid transparent;

  @if $triangle=='top'{
	border-bottom: $size solid $color;
  }
  @else if $triangle=='bottom'{
	border-top: $size solid $color; 
  }
  @else if $triangle=='left'{
		border-right:$size solid $color;
  }
  @else if $triangle=='right'{
		border-left:$size solid $color;
  }
}

@mixin  make-triangle($triangle, $size, $color){ 
	@include make-triangle_private($triangle, $size, $color); 
}