.pos-queue-task-header{
	display: flex;
    flex-direction: column;
    box-shadow: 0 0 15px rgba(0,0,0,.25);
    padding: 0.9375rem;
    background-color: $brand-color-11;
    margin-bottom: 1rem;

    &__heading {
		&_label {
			width: 100%;
		    border-bottom: 1px solid $brand-color-2;
		    font-weight: 600;
		    font-size: 1.25rem;
		    margin-top: 0.375rem;
  			color: $brand-color-2;
		}
	}
    &__details {
		display: flex;
		margin-top: 0.5rem;
    	font-size: 1rem;

		&_booking-info {
			display: flex;
    		flex-direction: column;
    		margin-right: auto;
		}
		&_pnr-info {
			display: flex;
    		flex-direction: column;
		}
		.booking-info {
			&__reference-no {
				&_value {
					font-family: $font-family-number;
					>a {
						color: $brand-color-1;
						&:hover {
							color: darken($brand-color-1, 10);
						}
					}
				}
			}
			&__reference-no,
			&__supplier {
				display: flex;
				&_label {
		  			color: $brand-color-2;
		  			font-weight: 600;
				}
				&_separator {
				    margin: 0 0.5rem;
				}
			}
		}
		.pnr-info {
			&__customer-name,
			&__pnr {
				display: flex;
				&_label {
		  			color: $brand-color-2;
		  			font-weight: 600;
				}
				&_separator {
				    margin: 0 0.5rem;
				}
			}
		}
	}
}