@mixin create-icon-container_private($icon-size, $icon-margin-top, $icon-margin-bottom, $icon-margin-left, $text-margin-left, $text-top) {
	&__icon {
		position: absolute;
		margin-left: $icon-margin-left;
		font-size: $icon-size;
		margin-top: $icon-margin-top;
		margin-bottom: $icon-margin-bottom;
	}
	
	&__text {
		position: relative;
		margin-left: $text-margin-left;
		top: $text-top;
	}
}

@mixin  create-icon-container($icon-size, $icon-margin-top, $icon-margin-bottom, $icon-margin-left, $text-margin-left, $text-top) { 
	@include create-icon-container_private($icon-size, $icon-margin-top, $icon-margin-bottom, $icon-margin-left, $text-margin-left, $text-top); 
}