.pos-update-modal{

	&__table-data{
		width:100%;
		margin-top: 20px;
		
		&_table-pax-name-td{
			font-size: 14px;
			width: 50%;
			padding: 10px;
			color:#825e44;
		
		}
		&_gv-dv-header{
			padding-left: 0px;
		}
		&_table-data-td{
			font-size: 12px;
			width: 50%;
			padding: 10px;
			color:#825e44;
		}
		
		&__table-input-field{
		    height: 25px;
		}
	}

	&__label{
		    display: block;
    	float: left;
    	width: 50%;
    	padding-top: 5px;
    	padding-right: 12px;
    	padding-bottom: 0px;
    	padding-left: 0px;
    	margin-top: 10px;
    	color:#825e44;
	
	}
	
	&__input-field{
		margin-top: 10px;
    	width: 260px;
    	height: 25px;
    	padding: 0 3px;
	
	}
	
	&__info-box{
		border-color:#825e44;
   		border: 2px solid;
   		padding: 10px;
   		
   		&_bk-disc{
   		 
   		 font-size: 14px;
   		 color:#825e44;
   		
   		}
   		
	}
	
	&__invoice-no-div{
		padding-bottom: 20px;
	}
	
	&__error-message{
		color:red;
	};

}