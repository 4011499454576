.queue-management-list {
	
	&__search , &__remote-filter {
		@include clearfix;
	}
	&__action-bar{ 
      @include clearfix;
	  width:100%;
	}
	&__header {
		@include clearfix;
		@include make-header-title;
		width : 100%;
		&_title{
			width : 35%;
			float:left;
		}
		&_search-box{
		    float: right;
		    margin-top: 0.5rem;
		}
		
		&-with-remote-filter{
		    width:75%;
		}
		
		&_action-bar {
			float: right;
			width:100%;
			position:relative;
		}
		&_resend{
			float: right;
    		margin-top: .5rem;
		}
		&_remote-filter{
			float: right;
	    	height: 1px;
	    	margin-right: 17rem;
		}
	}
	&__pagination{
		margin-top:0.625rem;
		margin-bottom:0.625rem;
		width: 25%;
    	float: right;
	}
	
	&__transaction-count{
		margin-top:0.625rem;
		margin-bottom:0.625rem;
		width: 25%;
   float: left;
	}
}