.oauth-login-callback{

    &_wrapper {
		@include clearfix;
		margin: auto;
	    width: 720px;
    }

    &_card{
		box-shadow: $row-shadow-color;
	    padding: 10px 3rem;
        background-color: $brand-color-4;
        margin-top:0.75rem;
	}

    &__header{
    	text-transform: none;
   		 color: $brand-color-2;
   		 padding: calc($base-padding/6) calc($base-padding/2);
	     text-align: center;
   		 
   		 &_text{
		    font-size: 1.5rem;
	     	font-weight: 700;
   		 }
   		 	
	}
}