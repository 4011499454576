%action-buttons {
	background-color: $brand-color-2;
    border: 0;
    color: $brand-color-11;
    margin-left: auto;
	padding: 0.5rem;
	&:hover {
		background-color: darken($brand-color-2, 20);
	}
}
.pos-queue-task-details {
	&__heading {
		display: flex;
		align-items: center;
		&_title {
			color: $brand-color-2;
			font-size: 1.5rem;
			margin-right: auto;
		}
		&_back-to-queue-list {
			.back-to-queue-list {
				color: $brand-color-2;
			    font-size: 1rem;
				&:hover {
					color: darken($brand-color-2, 20);
				}
			}
		}
	}
	&__content {
		.queue-task-header {
			&__heading {
				display: flex;
				align-items: center;
				&_product-icon,
				&_pnr {
					margin-left: 0.75rem;
				}
				&_pnr {
					color: $brand-color-2;
					font-size: 1.25rem;
					font-weight: 700;	
				}
			}
		}
		.queue-task-content {
			&__controls {
				display:flex;
				justify-content: flex-end;
				margin-bottom: 0.5rem;
				&_remove-all-button {
					margin-right: 0.5rem;
				}
				&_remove-all-button,
				&_history-button {
					>button {
						@extend %action-buttons;
					}
				}
			}
			&__controls-display-container {
				margin-bottom: 0.5rem;
			}
			&__itinerary {
				margin: 1rem 0; 
				&_label {
					font-size: 1.25rem;
					color: $brand-color-2;
					font-weight: 600;
				}
				&_headers {
					background-color: $brand-color-3;
					text-transform: uppercase;
				} 
				&_headers, 
				&_segments {
					display: flex;
					border: 1px solid $gray-lighter;
					border-top: 0;
					> div {
						width: 100%;
						padding: 0.5rem;
					}
				}
			}
			&__queue-heading {
				color: $brand-color-2;
				font-size: 1.25rem;
				font-weight: 600;
			}
			&__queue-info {
				border: 1px solid $gray-lighter;
				margin-bottom: 1rem;
				&_queue-category {
    				background-color: $brand-color-3;
    				color: $brand-color-2;
    				font-weight: 600;
					font-size: 1rem;
					padding: 0.5rem;
					display: flex;
					> span {
						margin-right: 0.25rem;
					}
				}
				&_queue-task {
					margin: 0.5rem;
					padding: 0.25rem;
					&:not(:last-child) {
						border-bottom: 1px solid $gray-lighter;
					}
					.queue-task {
						&__actions {
							display: flex;
							align-items: center;
		   					padding: 0.5rem;
							&_status {
							    padding: 0.25rem;
							    margin-left: auto;
							    text-transform: uppercase;
							    font-weight: 600;
							}
							&_action-controls {
							    margin-left: auto;
								.action-controls {
									&__accept,
									&__cancel,
									&__remove {
									   @extend %action-buttons;
									}
								}
							}
						}
						&__creation-date {
							font-size: 1rem;
							font-weight: 600;
							&_label {
								color: $brand-color-2;
							}
							&_value {
								font-family: $font-family-number;
							}
						}
					}
				}
			}
			&__tasks-actioned-message {
				margin: 1rem 0;
			    padding: 1rem;
			    text-align: center;
			    color: $brand-color-2;
			    font-weight: 600;
			    font-size: 1rem;
			    background-color: lighten($brand-color-3, 5%);
			}
		}
	}
}