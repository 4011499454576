.pos-dependent-list {
	@include create-row-card;

	&_card{
		padding : 0px;
		box-shadow: $row-shadow-color;
	}	
	
	&__header{
		@include make-row;
		@include make-header-title;
		@include make-action-bar;
		padding-bottom: calc($base-padding/3);
		width: 100%;
		display : table;
	}
	
	@include make-user-details-container;

	.dependent{
		@include make-personal-details-container;
		.personal-details{
			width: 80%;
		}
		.user-controls{
			width: 20%;
			margin-top: 3px;
			&__container {
				&_activate{
					margin-top: 0;
				}	
			}
		}
		
		&__contact-details {
			
			&_mobile {
				.mobile-details {
					@include create-icon-container(1.75rem, -4px, -4px, 0, 20px, 7px);
				}
			}
			&_nationality {
				.nationality-details {
					@include create-icon-container(1.5rem, 0, 0, 6px, 30px, 7px);
				}
			}
			&_email {
				margin-top: 5px;
				
				.email-details {
					@include create-icon-container(1.1rem, 4px, 0, -1px, 22px, 7px);
				}
			}
		}
	}
	
	&__loader{
		@include create-loader-background;
		margin: 0;
		float : left;
		width : 100%;
	}
	
	&__status-msgs{
	    text-align: center;
       	padding : 1rem;
        font-size: $font-size-h3;
	}
}

