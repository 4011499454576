@mixin make-row_private {
   @include clearfix;
}

@mixin make-block_private($gutter: $grid-padding) {
  float: left;
  padding-left:  calc($gutter / 2);
  padding-right: calc($gutter / 2);
  min-height: 1px;
}

@mixin make-one-full_private() {
  @include make-block;
  width: 100%;
}
@mixin make-one-half_private() {
  @include make-block;
  width: 50%;
}
@mixin make-one-third_private() {
  @include make-block;
  width: 33.333%;
}

@mixin make-two-third_private() {
  @include make-block;
  width: 66.666%;
}
@mixin make-one-fourth_private() {
  @include make-block;
  width: 25%;
}
@mixin make-one-fifth_private() {
  @include make-block;
  width: 20%;
}
@mixin make-three-fifth_private() {
  @include make-block;
  width: 60%;
}

// Extra grid classes
@mixin make-three-fourth_private {
  @include make-block;
  width: 75%;
}
@mixin make-four-fifth_private {
  @include make-block;
  width: 80%;
}
@mixin make-two-fifth_private {
  @include make-block;
  width: 40%;
}


@mixin make-one-sixth_private {
  @include make-block;
  width: 16.66%;
}
@mixin make-five-sixth_private {
  @include make-block;
  width: 83.33%;
}
@mixin make-one-seventh_private {
  @include make-block;
  width: 14.28%;
}
@mixin make-one-eight_private {
  @include make-block;
  width: 12.5%;
}
@mixin make-two-seventh_private {
  @include make-block;
  width: 28.57%;
}

@mixin  make-row { 
	@include make-row_private; 
}
@mixin  make-block($gutter: $grid-padding) { 
	@include make-block_private($gutter: $grid-padding); 
}
@mixin  make-one-full() { 
	@include make-one-full_private(); 
}
@mixin  make-one-half() { 
	@include make-one-half_private(); 
}
@mixin  make-one-third() { 
	@include make-one-third_private(); 
}
@mixin  make-two-third() { 
	@include make-two-third_private(); 
}
@mixin  make-one-fourth() { 
	@include make-one-fourth_private(); 
}
@mixin  make-one-fifth() { 
	@include make-one-fifth_private(); 
}
@mixin  make-three-fifth() { 
	@include make-three-fifth_private(); 
}
@mixin  make-three-fourth { 
	@include make-three-fourth_private; 
}
@mixin  make-four-fifth { 
	@include make-four-fifth_private; 
}
@mixin  make-two-fifth { 
	@include make-two-fifth_private; 
}
@mixin  make-one-sixth { 
	@include make-one-sixth_private; 
}
@mixin  make-five-sixth { 
	@include make-five-sixth_private; 
}
@mixin  make-one-seventh { 
	@include make-one-seventh_private; 
}
@mixin  make-one-eight { 
	@include make-one-eight_private; 
}
@mixin  make-two-seventh { 
	@include make-two-seventh_private; 
}