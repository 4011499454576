.pos-pricing-model-rule-list {
    
    @include create-row-card;
    @include clearfix;
    
    &_card {
        box-shadow: $row-shadow-color;
    }
    
    &__details {
        @include clearfix;
        &_data {
            @include clearfix;
            padding-bottom: 1rem;
        }
        
        &_list-container {
            margin-bottom: 3rem;
            background-color: $brand-color-11;
            border: 1px solid $brand-color-3;
            .list-container {
                &__heading {
                    color: $brand-color-3;
                    background-color: $brand-color-3;
                    padding: 0.25rem;
                    border-bottom: 1px solid $brand-color-3;
                    &_title {
                        font-size: 1rem;
                        color: $brand-color-2;
                        margin: 0.25rem 0 0.25rem 1rem;
                    }
                }
                &__body {
                    &_rule-list {
                        min-height: 3rem;
                    }
                    .rule-list {
                        &__row {
                            border-bottom: 1px solid $brand-color-3;
                            cursor: move;
                        }
                    }
                }
            }
        }
    }
    &__wrapper {
        @include clearfix;
    }
    &__status-msgs {
        text-align: center;
        font-size: $font-size-h3;
        &_text{
        	padding: 0.3rem 1rem 0.3rem 1rem;
        	
        }
    }
    &__loader {
        @include create-loader-background;
        margin: 0;
        float: left;
        width: 100%;
    }
    &__header {
        @include make-row;
        @include make-header-title;
        @include make-action-bar;
        padding-bottom: calc($base-padding/3);;
        display: table;
        width: 100%;
    }
    
    &__header{
    	&_title, &_action-bar{
    		width:50%;
    	}
    	.action-bar{
	        @include clearfix;
	        float: right;
	        &__search , &__add-button, &__save-rule, &__reset-rule{
	        	float:left;
			    margin-left: 10px;
	        }
	        &__add-button{
	        	>button{
	        		height: 2rem;
	        	}
	        }
 			&__save-rule, &__reset-rule {
				>button {
					@include make-button($brand-color-11, $brand-color-2, capitalize);
					@include button-size(10px ,10px, 15px, 0, 4px, 100%);
				    height: 2rem;
                    &:disabled {
                        &:hover {
                            cursor: not-allowed;
                        }
                    }				
				}		    
			} 	        
    	}
    }
    @include make-user-details-container;
    
    .pricing-rule {
        @include make-row;
        padding: 0.6rem 0 0.6rem 2rem;
        &__header {
            @include make-row;
            &_user-controls {
                float: left;
                width: 20%;
            }
            &_number-stepper {
                color: #aaa;
                width: 20%;
                float: left;
                min-height: 1px;
            }
            &_model {
                font-size: 1.25rem;
                float: left;
                width: 60%;
                color: #aaa;
                .model-id__title {
                    color: $brand-color-2;
                }
            }
        }
    }
    .user-controls {
        &__container {
            float: right;
            width: 100%;
            font-size: 0.75rem;
            &_activate {
                float: right;
                margin-top: 3px;
                margin-left: calc($base-margin/5);
                cursor: pointer;
            }
            &_edit, &_copy, &_delete, &_down, &_up, &_activation {
                cursor: pointer;
                min-height: 1px;
			    background: transparent;
			    border: none;
			    padding: 0; 
                &:disabled {
                    &:hover {
                        cursor: not-allowed;
                    }
                }             
            }
            &_down, &_up {
                float: left;
                width: 15%;
            }
        }
    }
    .pricing-rule__header_user-controls {
        .user-controls__container {
            width: 65%;
        }
    }
    .dndPlaceholder {
        background-color: $brand-color-11;
        display: block;
        min-height: 3rem;
    }
    .dndDragging {
        border: 1px solid $brand-color-3;
    }
}