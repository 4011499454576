.pos-trans-quick-search {
	.itemcontainer {
		box-shadow: 1px 7px 50px 2px rgba(168,166,168,.5);
		max-height: 500px;
		overflow-y: hidden;
		z-index: 5;
		position: absolute;
		background-color: $light;
		border-top: 1px solid $brand-color-2;
		border-right: 1px solid $gray-lighter;
		border-bottom: 1px solid $gray-lighter;
		border-left: 1px solid $gray-lighter;
		margin-top: -1px;
		left: 0;
		right: 0;
	}
	.selecteditem {
	    background-color: $brand-color-3;
	}
	.autosuggestion {
		position : relative;
		&__item {
	    	&:hover,
	    	&:focus {
		    	cursor: pointer;
			    background-color: $brand-color-3;
	    	}
			&_title{
			    color: $brand-color-2;
			    font-size: .875rem;
			    padding-left: 5px;
			    padding-right: 5px;
			    padding-top: 5px;	
			}
			
			&_subtitle{
			    border-bottom: 1px solid $brand-color-3;
			    margin-left: 5px;
			    margin-right: 5px;	
			}	
		}
	}
}
