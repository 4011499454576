.pos-customer-list {
	@include create-row-card;
	@include create-row-table;
	@include clearfix;
	
	&_card {
		float: left;
		padding : 0px;
		box-shadow: $row-shadow-color;
	}	
	
	&__search {
		width: 100%;	
		padding-bottom: 0.625rem;
		@include make-header-title;
		display : table;
		
		&_quick-search {
			width: 40%;
			margin-top: calc($base-margin/2);
			display : table-cell;
			.quick-search {
				float: right;
			}
		}
	}
	
	&__header {
		width : 100%;
		display : table;
    	@include clearfix;
		@include make-header-title;
		@include make-action-bar;	
		
		&_action-bar {
			padding-bottom: .625rem;
		}	
	}
	
	@include make-user-details-container;
	
	.traveler{
		@include make-personal-details-container;
		@include clearfix;
		
		&__contact-details {
			&_mobile, &_designation-details{
				width: 100%;
			}
			
			&_mobile {
				@include clearfix;
				margin-bottom: 0.5rem;
			}
		}
		
		&__personal-details_user {
			 .user-controls {
		    	&__container_edit {
		    		position: relative;
		    		top: 4px;
		    	}
		    }
		}
	}
	
	.customer-type{
		&__location-details , &__client-type-details{
			width : 50%;
			float: left;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	
	.mobile-details {
		@include create-icon-container(1.75rem, -0.25rem, -0.25rem,  0.375rem, 1.875rem, 0.4375rem);
		&__text{
			float:left;
		}
	}
	
	.client-type-details {
		@include create-icon-container(1rem, 0.1875rem, 0, 0.25rem, 1.875rem, 0.25rem);
	}
	.location-details {
		@include create-icon-container(1.25rem, 0, 0, 0.35rem, 1.575rem, 0.2rem);
	}
	
	&__wrapper {
		@include clearfix;
    }
    
    .personal-details-company-code {
    	float: left;
		@include create-icon-container(1rem, 0, 0, 0.3125rem, 1.875rem, 0px);
    }
    
    .user-controls {
    	width: 17%;
    	
    	&__edit {
    		position: relative;
    		top: 4px;
    	}
    }
	
	&__loader{
		@include create-loader-background;
		margin: 0;
	}
	&__status-msgs{
	    text-align: center;
       	padding : 1rem;
        font-size: $font-size-h3;
	}
}
