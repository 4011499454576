.pos-pricing-model-rule-form {
	@include create-row-card;
	@include clearfix;
	
	&_card {
		box-shadow: $row-shadow-color;
	}
	&__header{
		@include make-header-title;
		width: 100%;
		display : table;
		&_back-to-list{
			float: right;
		    font-size: 1rem;
		}
	}
	&__wrapper {
		@include clearfix;
    }
    
    &__list{
   	    margin-top: 1rem;
    	margin-bottom: 1rem;
    	&_rule-overview{
			@include clearfix;
    	}
    	&_rule-form{
			@include clearfix;
    	}
    	.rule-form {
    		&__title{
    			padding:0 1rem;
    			font-size:1.2rem;
    		}
    	}
    	.rule-overview {
    		&__template, &__rule , &__description {
	    		input[type="text"]{
	    			width:100%;
				    padding: .5rem 1rem;
				    background: $light;
				    font-size: .875rem;
				    vertical-align: middle;
				    line-height: normal;
				    border: 1px solid $brand-color-10;
				    max-height: 34px;
	    		}
	    	}
	    	
	    	&__title{
	    		padding:0 1rem;
    		    font-size: 1.2rem;
	    	}
	    	
	    	&__template{
	    		padding: 1rem;
	    	}
	    	
	    	&__rule, &__description {
	    		@include make-one-half;
	    	}
	    	&__rule{
			   	&_validation-error {
					color: $brand-color-1;
				}
	    	}
	    	&__description{
		    	textarea {
	    			width:100%;
					background: none;
					font-size: 0.875rem;
					vertical-align: middle;
					line-height: normal;
					height: 4.5rem;
					background-color: $light;
					border: 1px solid $brand-color-10;
				}
	    	}
    	}
    }	
	label{
		width:100%;
		margin-bottom: 0.25rem;
		color: $brand-color-2;
		font-size: 0.875rem;
	}
}
