.pos-pnr-itinerary-info {
	margin: 1rem 0; 
	&__headers {
		background-color: $brand-color-3;
		text-transform: uppercase;
		color: $brand-color-2;
    	font-weight: 600;
	} 
	&__headers, 
	&__segments {
		display: flex;
		border: 1px solid $gray-lighter;
		border-top: 0;
		> div {
			width: 100%;
			padding: 0.5rem;
		}
		&_flight {
			.flight {
				&__flight-no {
					font-family: $font-family-number;
				}
			}
		}
		&_departure-date{
			.departure-date{
				&__date,
				&__time {
					font-family: $font-family-number;
				}
			}
		}
		&_arrival-date{
			.arrival-date {
				&__date,
				&__time {
					font-family: $font-family-number;
				}
			}
		}
	}
}
