.pos-agent-refund-details{
	@include create-main-wrapper;
	
	&__content{
		 @include clearfix;
		
		&_product-info{
		    @include clearfix;
			padding : calc($base-padding/3);
			margin : calc($base-padding/3);
	    }
	}
}