.pos-traveler-list {
	@include create-row-card;
	
	&_card{
		padding : 0px;
		box-shadow: $row-shadow-color;
	}	
	
	&__header{
		@include make-row;
		@include make-header-title;
		@include make-action-bar;
		padding-bottom: calc($base-padding/3);
		display : table;
		width: 100%;
	    &_title{
	    	vertical-align:middle;
			float: left;
	    }
		&_action-bar{
			float: right;
    		margin-top: 0.5rem;
		}
		&_remote-filter{
		float: right;
    	position: relative;
    	width: 100%;
			.remote-filter-pagination{
				    float: right;
   					margin-right: 20rem;
    				position: absolute;
    				right: 0;
    				top: -2.5rem;
			}
			.traveler-remote-filter{
				border: 10px solid $brand-color-3;
				margin-top: 0.3rem;
			}
		}
	}
	
	@include make-user-details-container;

	.traveler{
		@include make-personal-details-container;
		
		&__contact-details {
			
			&_mobile {
				.mobile-details {
					@include create-icon-container(1.75rem, -0.25rem, -0.25rem, 0, 1.25rem, 0.4375rem);
					
					&__text {
						margin-right: 0.5rem;
					}
				}
			}
			&_nationality {
				.nationality-details {
					@include create-icon-container(1.5rem, 0, 0, -0.0625rem, 1.5rem, 0.4375rem);
				}
			}
			&_email {
				margin-top: 5px;
				
				.email-details {
					@include create-icon-container(1.1rem, 0.25rem, 0, -0.0625rem, 1.375rem, 0.4375rem);
				}
			}
		}
	}
	
	.details__text{
		margin-left: 3px;
  	  	margin-right: 6px;
	}
	.details__icon{
		font-size: 20px
	}
	&__loader{
		@include create-loader-background;
		margin: 0;
		float : left;
		width : 100%;
	}
	&__status-msgs{
	    text-align: center;
       	padding : 1rem;
        font-size: $font-size-h3;
	}
}