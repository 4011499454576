.pos-pagination{
	&__button {
		@include make-button($brand-color-2,$light,capitalize);
		@include button-size(0.3125rem ,0.3125rem, 1rem, inherit, 0.625rem, 100%);
		border: 2px solid $brand-color-2;
		font-weight: 600;
	    &:hover {
	      background-color: $brand-color-2;
	      color:$light;
	    }
		&:disabled {
		  background-color: lighten($brand-color-2, 20);
		  color:$light;
		  &:hover {
		    background-color: lighten($brand-color-2, 20);
		  }
		  cursor: not-allowed;
		}    
	  }
}