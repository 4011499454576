.pos-queue-action-status {
	display: inline;
	&__new {
		color: $queue-mgmt-status-new;
	}
	&__accepted {
		color: $queue-mgmt-status-accepted;
	}
	&__rejected {
		color: $queue-mgmt-status-rejected;
	}
	&__removed {
		color: $queue-mgmt-status-removed;
	}
	&__value {
		font-size: 1rem;
	}
}