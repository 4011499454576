.routed-popover {
    position: relative;

    &__route-link {
		cursor: pointer;
        text-decoration: none;
        font-size: 1.25rem;
		&:hover{
	        text-decoration: none;
		}
    }

    &__view {
        display: none;
        position: absolute;
        left: 50%;
        top: 3rem;
        transform: translateX(-75%);

        &:before {
            content: '';
            border: 1rem solid $brand-color-4;
            border-left-color: transparent;
            border-right-color: transparent;
            border-top-color: transparent;
            height: 0;
            position: absolute;
            left: 75%;
            top: -32px;
            width: 0;
            z-index: 1;
    		filter: drop-shadow(0 -4px 3px rgba(0,0,0, 0.2));
        }
    }
    .open {
        display: block;
    }
}