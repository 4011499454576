.pnr-queue-history {
	border: 8px solid $brand-color-3;
	margin: 1rem 0;
	padding: 1rem;
	padding-top: 0;
	display: flex;
	flex-direction: column;
	&__close-button {
		font-size: 2rem;
    	align-self: flex-end;
    	cursor: pointer;
	}
	&__header {
		color: $brand-color-2;
		font-size: 1.25rem;
		border-bottom: 1px solid $brand-color-3;
		text-align: center;
		width: 100%;
		margin-bottom: 0.5rem;
		padding-bottom: 0.5rem;
	}
	&__content {
		&:not(:last-child) {
			border-bottom: 1px solid $brand-color-3;
		}
		padding: 1rem 0;
		font-size: 1rem;
		&_audit-info {
			display: flex;
    		.audit-info {
				&__audit-by {
					margin-right: 0.25rem;
					&_value {
						font-weight: 700;
						color: $gray;
					}
				}
				&__audit-date {
					&_value {
						color: $gray;
						font-family: $font-family-number;
						font-weight: 700;
					}
				}
				
			}
		}
		&_user-remarks {
			display: flex;
			.user-remarks {
				&__label {
					color: $brand-color-2;
					font-weight: 600;
				}
				&__separator {
					margin: 0 0.5rem;
				}
			}
		}
		&_history-info {
			.history-info {
				&__queue-info,
				&__status {
					display: flex;
					&_label {
						color: $brand-color-2;
						font-weight: 600;
					}
					&_changed-from-label,
					&_changed-to-label {
						margin: 0 0.25rem;
					}
					&_changed-from-label {
						margin-left: 0;		
					}
					
					&_separator {
						margin: 0 0.5rem;
					}
				}
			}
		}
	}
}
