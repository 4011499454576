.pos-date-range-drop-down{
	
	&__drop-down{
		@include clearfix;
		width:100%;
		padding-bottom: calc($base-padding/2);
		&_title{
			font-size: inherit;
			color: inherit;
		}
		&_select{
	    	border-radius: 0px; 
	    	color: inherit;
		}
	
	}
	
	.search-date_select {
		float: left;
		padding-right: calc($base-padding/2);
		min-height: 1px;
		width: 50%;
	}
}