.pos-task-list-container {
	@include create-main-wrapper;
  &_wrapper {
    @include clearfix;
  }
  width: 100%;

  &__header {
    width: 100%;
    display: table;
    @include clearfix;
    @include make-header-title;
    @include make-action-bar;
    &_action-bar {
      padding-bottom: calc($base-padding/3);
      width: 100%;
      .action-bar {
        &__add-button {
          > button{
            padding: 5px 10px;
            font-size: $font-size-h4;
          }
          &_selected-filter-count{
          	@include round-icon-with-count;
          	margin-top: 0rem;
          	float: right;
          }
        }

      }
      @include make-responsive($desktop-sm){
        padding-bottom: calc($base-padding/3);
        width: 50%;
      }
    }	
    
    
    &_title{
      display: none;
      width: 0%;
        @include make-responsive($desktop-sm){
          width: 50%;
          display: block;
        }
    }
  }
  &__pagination {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
	width: 50%;
    float: right;
	    @include make-responsive($desktop-sm){
		    width: 25%;
      	}
  }
}

