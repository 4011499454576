.guest-house-inventory-bulk-modal{
	border: 5px solid $brand-color-3;
    height: 150px;
	&__search-form{
		margin-top: 0.525rem;
    	height: 5rem;
		&_header{
			 padding-right: 1rem;
		}
		&_from-date,&_to-date,&_single-room,&_close-out{
			float: left;
    		height: 4.375rem;
    		margin-top: 0.525rem;
    		padding-left: 1.625rem;
    		width: 20%;
			.label{
				color: $brand-color-2;
    			font-size: .875rem;
    			margin-bottom: 0.25rem;
    			width: 100%;
				text-align: left;
				padding-left: 0px;
			}
			.data-input{
				    background: 0 0;
    background-color: $light;
    border: 1px solid $gray-lighting;
    font-size: .875rem;
    height: 2.1875 rem;
    line-height: normal;
    padding: 0.5rem 1rem;
    vertical-align: middle;
			}
		}
		&_close-out{
			padding-left: 5rem;
			.data-input{
 		    	height: 20px; 
    			width: 2.1875 rem;
				font-size: .875rem;
   		 		line-height: normal;
    			padding: 0.5rem 1rem;
    			vertical-align: middle;
    			text-align: center;
 				border: 0px;
			}
			.close-out__input{
				padding-left: 26px;
    			padding-top: 5px;
			}
		}
	}
	&__footer{
    float: right;
	display: inline-block;
    margin-bottom: 0.525rem;
    padding-right: 15rem;
    width: 10%;
		&_bulk-update-inventory-button{
			border: 1px solid $gray-lighting;
			background-color: $brand-color-1;
    		color: $light;
    		margin-right: 0.125rem;
    		padding: 0.5rem;
    		width: 12.5rem;
		}
	}
	
}