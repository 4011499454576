.pos-report-form {
	padding: 0.9375rem;
	.dynamic-form {
		&__save-button {
			padding: 0 0.9375rem 0 0;
			&_submit {
				margin: 0;
			}
		}
	}
}
