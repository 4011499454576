@import "modules/pos/master/trans-manager/pos-dmo-gv/pos-dmo-gv-list/_pos-dmo-gv-row";
.pos-dmo-gv-void-row{
    	
    &__header{
		padding: 0.3125rem 0.3125rem 1.5rem 0.3125rem;
		
		&_booking-number, &_dmo-gv-number, &_audit-date, &_status, &_serial-number ,&_invoice-number{
			float: left;
			text-align: center;
			width: 16.66%;
			height: 1.5625rem;
			.info-icon {
				padding-left: 0.65rem;
			}
		}
		&_booking-number{
			text-align: left;
			width: 16.66%;
		}
		&_booking-number {
		    color: $brand-color-2;
			font-size: 1rem;
		}
		&_status {
	    	font-size: 1.125rem;
    		color: $brand-color-1;
			height: 1.5625rem;
		}
		&_total-amount{
		    float: left;
		    width: 16.66%;
			font-size: 1rem;
		    text-align: right;
		    &_currency {
		    	font-size: 0.75rem;
		    }
		    
		    &_amount {
	    		font-size: 1.125rem;
    			color: $brand-color-1;
		    }
		}
	}
	
	&__details {
	    
	    .details-container {
			&__product-desc {
		    	width: 60%;
			}
			&__action-buttons {
		    	width: 30%;
    			text-align: center;
			}
			&__price {
		    	width: 10%;
			}
	    }
	}
}

.custom-serial-no-popover-class{
	color: $brand-color-2;
}