.pos-queue-pnr-action-form {
	border: 8px solid $brand-color-3;
    padding: 1rem;
    &__categories-to-be-actioned {
		display: flex;
		margin-bottom: 1rem;
		&_label,
		&_separator {
			color: $brand-color-2;
			font-size: 1rem;
			font-weight: bold;
		}
		&_separator {
			margin-right: 0.25rem;
		}
		&_list {
			font-size: 1rem;
			font-weight: bold;
		}
	}
    &__heading {
		font-size: 1.25rem;
	    color: $brand-color-2;
	    font-weight: 600;
	    text-align: center;
	    border-bottom: 1px solid $brand-color-3;
	    padding-bottom: 0.5rem;
	    margin-bottom: 1rem;
	}
	&__field {
		display: flex;
    	flex-direction: column;
		&_label {
			color: $brand-color-2;
		}
		&_remarks {
			textarea {
				width: 50%;
				font-size: 0.875rem;
				vertical-align: middle;
				line-height: normal;
				border: 1px solid $input-border-color;
			}
		}
		&_validation-error {
			color: $brand-color-1;
		}
		&_terms-field {
			margin: 0.25rem 0;
			.terms-field {
				&__checkbox {
					margin-right: 0.25rem;
				}
			}
		}
	}
	&__control-buttons {
	    margin-top: 1rem;
    	display: flex;
    	width: 50%;
    	&_action {
	    	margin-right: 1rem;
		}
	    &_action,
	    &_close {
			color: $brand-color-11;
			background-color: $brand-color-1;
		    border: 0;
			width: 50%;
			padding: 0.5rem;
			&:hover {
				background-color: darken($brand-color-1, 20);
			}
			&:disabled {
				background-color: lighten($brand-color-1, 20);
				cursor: not-allowed;
			}
		}
	}
}