@import "modules/pos/master/trans-manager/pos-dmo-gv/pos-dmo-gv-list/_pos-dmo-gv-list";
.pos-dmo-gv-void-list {

	&__content{
		
		.headers{
			text-transform: uppercase;
			&__booking-no, &__status , &__amount,&__invoice-no,&__product,&__serial-no{
				float: left;
				min-height: 0.0625rem;
			    padding-left: 0.3rem;
			    padding-right: 0.3rem;
			}
			&__booking-no {
				width: 16.66%;
			}
			&__status, &__product,&__invoice-no,&__serial-no{
				width: 16.66%;
				text-align: center;
			}
			&__amount{
				width: 16.66%;
				text-align: right;
			}
		}
	}
}

.pos-dmo-gv-void-conform-modal {
	font-size: .875rem;
	position: relative;
	.modal-content{
        width: 40%;
        margin: 0 auto;
        top: 15rem;
		border-radius:6px;
		padding: 0;
	}
    font-weight: 600;
    text-align: center;
	    &__content {
	    	padding: 0.9375rem;
			&_text{
		    	color: $brand-color-2;
		    	padding-bottom: calc($base-padding/3);
		    	margin-top: 5%;
    			text-align: center;
	    	}
	    &_buttons, .buttons{
			margin-top: 1rem;
	    	min-width: 12.5rem;
	    	padding: .9375rem;
	    	width: 100%;
	    	&__close-button, &__okay-button{
					@include make-button($brand-color-11, $brand-color-2,capitalize,$brand-color-11,darken($brand-color-2, 20));
					@include button-size(1.125rem ,1.125rem, 0.875rem, 0, 0.25rem, 15%);
					min-width: 11.25rem;
				    height: 1.875rem;
	    	}
	    }
	    &_close {
	    	float: right;
    		cursor: pointer;
	    	color: $brand-color-2;
			position: absolute;
		    right: 0.625rem;
    		top: 0.313rem;
	    }
	}
}