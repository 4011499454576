.refund-modal{
    max-width: 40%;
    margin-left: 30%;
    margin-top: 10%;
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    	&__text{
		    color: $brand-color-2;
		    padding-bottom: calc($base-padding/3);
    		margin-left: -37%;
            margin-right: -37%;
            font-size: 1.3rem;
	    }
	    &__button{
    	    width: 30%;
			padding: calc($base-padding/5);
			background-color: $brand-color-3;
			border: none;
            font-size: 1rem;
	    }
}
.modal-open{
	.refund-modal.modal{
		overflow-x: hidden;
    	overflow-y: hidden;
	}
}


.credit-note-confirmation-modal, refund-warning-message-modal {
    font-size: 0.75rem;
    @include clearfix;
    font-weight: 600;
    text-align: center;
	    &__content {
	    	border: 2px solid $brand-color-2;
	    	padding: 0.9375rem;
			&_text{
		    	color: $brand-color-2;
		    	padding-bottom: calc($base-padding/3);
    			font-size: 0.875rem;
		    	margin-top: 5%;
		    	margin-bottom: 2%;
    			text-align: left;
	    	}
	    &_buttons, .buttons{
			@include clearfix;
	    	min-width: 12.5rem;
	    	width: 100%;
	    	border:none;
	    	&__close-button, &__okay-button{
	    		color: $brand-color-2;
	    		width: 12%;
				padding: calc($base-padding/5);
				background-color: $brand-color-3;
				font-weight: 600;
	    	}
			&__okay-button{
				float: right;
				border:none;
			}
			&__close-button{
				float: left;
				border:none;
			}
	    }
	    &_close {
	    	float: right;
    		cursor: pointer;
	    	color: $brand-color-2;
	    }
	}
}
