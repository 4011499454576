.pos-customer-form {
	@include create-main-wrapper;
	@include create-row-card;	
	@include create-card-content;
	@include create-form-row-container;
	@include create-form-row-details;		
	
	&__form{
		@include make-row;
		@include make-header-title;
		padding-bottom: calc($base-padding/3);
		display : table;
		width : 100%;
	}	
	
	&_card{
		box-shadow: $row-shadow-color;
	}
	&_content{
		@include create-nav-tabs;
	}	

	.nav-tabs>li>a{
		font-size: $font-size-h5;
	}

 	&__container {
		&_dropdown{
	 	     color: inherit;
			 font: inherit;
			 border-radius: 0px;
		}
		&_data-add-more{
			width: 90%;
			padding: 0.5rem 1rem;
			vertical-align: middle;
			line-height: normal;
			border: 1px solid$brand-color-10;
			max-height: 34px;
			margin-bottom: calc($base-margin/7);
		}
		
		&_action-icon{
        		padding : 0 3px;
        		width : 10%;
        		cursor :  pointer;
        }
		
		&_error{
			color: rgba(192,0,0,1);
		}
		> select{
		> option{
			max-width:50px;
			}
		}

		}
	
	&__address-area{
		max-height:60px;
	}	
	
	@include create-save-button(1rem,1rem,1rem,4rem, 20px, $brand-color-11, $brand-color-1,capitalize, 33%);
	
	&__loader{
		@include create-loader-background;
		margin: 0;
	}
	
	&__copy-trading-address-info{
		padding-bottom:0rem;
		&_wrapper{
			.copy-trading-address-info{
				&__container{
					padding: .3rem .9375rem;
				    min-height: 1px;
				    
					&_label{
						color: $brand-color-2;
					}
				}
			}
		}
	}
	
	input[type=number]::-webkit-inner-spin-button, 
	input[type=number]::-webkit-outer-spin-button { 
		-webkit-appearance: none; 
	}
	input[type=number] {-moz-appearance: textfield;}
	
	.form-details-container{
		@include clearfix;
	}
}	
