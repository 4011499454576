.pos-vendor-remarks {
	padding: 0.5rem 0;
	&__label {
		font-size: 1rem;
		color: $brand-color-2;
		font-weight: 600;
	}
	&__content {
		background-color: darken($brand-color-11, 5%);
		font-family: monospace;
		font-size: 1rem;
		line-height: 1.15;
		padding: 1rem;
	}
}