$desktop-xs: "(min-width: #{$xs-screen-size})";
$desktop-sm: "(min-width: #{$sm-screen-size})";
$desktop-md: "(min-width: #{$md-screen-size})";
$desktop-lg: "(min-width: #{$lg-screen-size})";
@mixin desktop-med {
  @media #{$desktop-md} {
    @content;
  }
}
@mixin desktop-large {
  @media #{$desktop-lg} {
    @content;
  }
}
@mixin desktop-small {
  @media #{$desktop-sm} {
    @content;
  }
}
@mixin make-responsive-width_private($property-name, $sm, $md:null, $lg:null, $xs:null) {
  #{$property-name}: $xs;
  @if $sm != null {
    @include desktop-small {
      #{$property-name}: $sm;
    }
  }
  
  @if $md != null {
    @include desktop-med {
      #{$property-name}: $md;
    }
  }
  @if $lg != null {
    @include desktop-large {
      #{$property-name}: $lg;
    }
  }
  @content;
}

@mixin  make-responsive-width($property-name, $sm, $md:null, $lg:null, $xs:null) { 
	@include make-responsive-width_private($property-name, $sm, $md, $lg, $xs); 
}

@mixin make-responsive_private($break-point: null) {
  @media #{$break-point} {
    @content;
  }
}

@mixin make-responsive($point) {
  @include make-responsive_private($point) {
    @content;
  }
}

