@mixin button-size_private($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius,$width) {
	 padding: $padding-vertical $padding-horizontal;
	 font-size: $font-size;
	 line-height: $line-height;
	 border-radius: $border-radius;
	 width: $width;
}

$display-null: null;

@mixin make-button_private($color, $bg-color, $text-transform, $hover-color: $display-null, $hover-bgcolor:$display-null) {
	color: $color;
	background-color: $bg-color;
	border: none;
	outline: none;
	text-transform: $text-transform;
	
	@if ($hover-color != $display-null and  $hover-bgcolor != $display-null) {
		
		&,
		&:active,
		&.active {
			
			&:focus,
			&.focus {
				@include tab-focus;
			}
		}
		
		&:hover,
		&:focus,
		&.focus {
			color: $hover-color;
			text-decoration: none;
			background-color: $hover-bgcolor;
			outline: none;
		}

		&:active,
		&.active {
			outline: 0;
			background-image: none;
			@include box-shadow(inset 0 3px 5px rgba(0, 0, 0, .125));
		}

		&.disabled,
		&[disabled],
		fieldset[disabled] & {
			cursor: $cursor-disabled;
			@include opacity(.65);
			@include box-shadow(none);
		}
	}
}

@mixin create-save-button_private($tpadding,$rpadding,$bpadding,$lpadding, $font-size, $text-color, $bgcolor,$text-transform, $width){
	&__save-button{
    	@include clearfix;
		padding: $tpadding $rpadding $bpadding $lpadding;
	    
		>button {
			float: right;
			font-size: $font-size;
			color: $text-color;
			background-color: $bgcolor;
			text-transform: $text-transform;
			width: $width;
		    border: none;		
			&.disabled,
			&[disabled] {
			    cursor: $cursor-disabled;
			    @include opacity(.65);
			    @include box-shadow(none);
			}
		}
	}
}

@mixin round-icon-with-count_private{
	float: left;
    width: 1.25rem;
    height: 1.25rem;
    background-color: $light;
    color: $gray;
    border-radius: 0.9375rem;
    border: 0.0625rem solid $gray;
    font-family: $font-family-number;
    font-size: .75rem;
    padding-top: 0.4rem;
    padding-right: 0.1rem;
    margin-top: -0.3rem;
    line-height: 0.5;
}

@mixin  button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius,$width) { 
	@include button-size_private($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius,$width); 
}
@mixin  make-button($color, $bg-color,$text-transform, $hover-color:$display-null, $hover-bgcolor: $display-null){ 
	@include make-button_private($color, $bg-color,$text-transform, $hover-color, $hover-bgcolor); 
}
@mixin  create-save-button($tpadding,$rpadding,$bpadding,$lpadding, $font-size, $text-color, $bgcolor,$text-transform, $width){ 
	@include create-save-button_private($tpadding,$rpadding,$bpadding,$lpadding, $font-size, $text-color, $bgcolor,$text-transform, $width); 
}

@mixin round-icon-with-count{
	@include round-icon-with-count_private;
}
