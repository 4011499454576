.pos-dependent-form {

	&__form{
		@include make-row;
		@include make-header-title;
		padding-bottom: calc($base-padding/3);
		display : table;
	    width: 100%;
	}
	
	&__add-new-dependent-wrapper {
	    padding: .9375rem;
	    background-color: $brand-color-11;
	    box-shadow: 0 0 15px rgba(0,0,0,.25);
	    width: 100%;
	    margin-bottom: 0.9375rem;
	    
	    &_save-button {
	    	@include clearfix;
		    margin-bottom: 0.725rem;
		    
			>button {
				float: right;
				@include make-button($brand-color-11, $brand-color-1, uppercase);
				@include button-size(0 ,0, 20px, 0, 0, 50%);				
			}
		}
}


 label {
 	margin-bottom: 0;
	color: $brand-color-2;
	font-size: 0.875rem;
 }

 .nav-tabs>li>a{
	font-size: $font-size-h5;
 }
	&__loader{
		@include create-loader-background;
		margin: 0;
		float : left;
		width : 100%;
	}
	
	.form-field-validation-error{
	    width: 100%;
    	color: $brand-color-1;
	}	

}