.pos-invoice-row{

		@include create-row-card;
		@include create-card-content;
	    box-shadow: $row-shadow-color;
	    @include clearfix;
	    padding: 0 ;
    	margin-bottom: .9375rem;
    	background-color: $light;
    	color: $gray-dark;
    	
    	&__invoice-header{
    		@include clearfix;
	   		width : 100%;
			padding: 0.3125rem 0.3125rem 0 0.3125rem;
		
		&_invoice-number{
		    float: left;
		    width: 15%;
		    color: $brand-color-2;
			font-size: 1rem;
		}
		&_doctype{
			float: left;
		    width: 15%;
			font-size: 0.8125rem;
		}
		&_audit-date{
			float: left;
		    width: 20%;
			font-size: 0.8125rem;
		}
		&_status {
		    float: left;
		    width: 30%;
	    	font-size: 1.125rem;
    		color: $brand-color-1;
    		text-align: right;
		height: 1px;
		}
		&_selling-amount {
		    float: left;
		    width: 20%;
			font-size: 1rem;
		    text-align: right;
		    &_currency {
		    	font-size: 0.75rem;
		    }
		    
		    &_amount {
	    		font-size: 1.125rem;
    			color: $brand-color-1;
		    }
		}
	}
	
	&__invoice-payheader{
    		@include clearfix;
	   		width : 100%;
			padding: 0.3125rem 0.3125rem 0 0.3125rem;
		
		&_invoice-number{
		    float: left;
		    width: 15%;
		    color: $brand-color-2;
			font-size: 1rem;
		}
		&_audit-date{
			float: left;
		    width: 25%;
			font-size: 0.8125rem;
		}
		&_status {
		    float: left;
		    width: 25%;
	    	font-size: 1.125rem;
    		color: $brand-color-1;
    		text-align: right;
		}
		&_selling-amount {
		    float: left;
		    width: 14%;
			font-size: 1rem;
		    text-align: right;
		    &_currency {
		    	font-size: 0.75rem;
		    }
		    
		    &_amount {
	    		font-size: 1.125rem;
    			color: $brand-color-1;
		    }
		}
		&_pending-amount {
		    float: left;
		    width: 14%;
			font-size: 1rem;
		    text-align: right;
		    &_currency {
		    	font-size: 0.75rem;
		    }
		    
		    &_amount {
	    		font-size: 1.125rem;
    			color: $brand-color-1;
		    }
		}
		&_payAmount {
			float: left;
			width: 15%;
			padding-left: 30px;
			&_label {
				
			}
			&_value {
				width:75%;
				text-align: right;
			}
		}
		&_remarks {
			float: right;
			width: 15%;
			padding-right: 15px;
			&_value {
				float: right;
				width:75%;
			}
		}
	}
	
	&__customer-details {
		@include clearfix;
	    width: 100%;
	    padding: 0.3125rem 0.3125rem 0px 0.3125rem;
		font-size: 0.8125rem;
    	
		&_name {
			float: left;
			width: 20%;
    	}
    	
    	&_travel-dt {
			float: left;
			width: 40%;
    	}
	}
	
	&__customer-paydetails {
		@include clearfix;
	    width: 100%;
	    padding: 0.3125rem 0.3125rem 0px 0.3125rem;
		font-size: 0.8125rem;
    	
		&_name {
			float: left;
			width: 20%;
    	}
    	
    	&_travel-dt {
			float: left;
			width: 40%;
    	}
	}
	
	&__details:nth-child(even) {
		background-color: $brand-color-11;
	}
	&__details:nth-child(odd) {
		background-color: $gray-lighter;
	}
	&__details {
		@include clearfix;
	    width: 100%;
	    font-size:0.875rem;
	    
	    &_details-container {
			@include clearfix;
	    	width: 100%;
	    	padding: 0.375rem;
	    }
	    
	    .details-container {
			&__product-type,{
				float: left;
		    	width: 15%;
		    	line-height: 1.5625rem;
    			min-height: 1.5625rem;
		    	
		    	.product-details__product-title {
		    		padding : 0 calc($base-padding/6);
		    	}
		    	.product-details__icon {
		    		float: left;
		    		color: $brand-color-2;
		    	}
			}
			&__product-desc {
				float: left;
		    	width: 40%;
		    	line-height: 1.5625rem;
    			height: 1.5625rem;
    			>div {
	    			text-overflow: ellipsis;
				    overflow: hidden;
				    white-space: nowrap;
    			}
			}
			&__status {
				float: left;
		    	width: 30%;
		    	color: $brand-color-2;
		    	line-height: 1.5625rem;
    			min-height: 1.5625rem;
    			text-align: left;
				padding-left: 6.25 rem;
				.popover {
					word-break: break-word;
					}
			}
			&__price {
				float: left;
		    	width: 15%;
		    	text-align: right;
		    	line-height: 1.5625rem;
    			height: 1.5625rem;
		    	
		    	.price__price-details {
		    		&_currency {
		    			font-size: .75rem;
		    		}
		    		&_amount {
		    			font-size: 1.125rem;
    					color: $brand-color-1;
		    		}
		    	}
			}
	    }

		&_productList{
		    @include clearfix;
  		    padding-left: 1rem;
			width : 100%;
		}
	}
	&__checkbox {
          position: absolute;
          left: 3%;
     }
     
     
    &__paydetails:nth-child(even) {
		background-color: $brand-color-11;
	}
	&__paydetails:nth-child(odd) {
		background-color: $gray-lighter;
	}
	&__paydetails {
		@include clearfix;
	    width: 100%;
	    font-size:0.875rem;
	    
	    &_details-container {
			@include clearfix;
	    	width: 100%;
	    	padding: 0.375rem;
	    }
	    
	    .details-container {
			&__product-type,{
				float: left;
		    	width: 15%;
		    	line-height: 1.5625rem;
    			height: 1.5625rem;
		    	
		    	.product-details__product-title {
		    		padding : 0 calc($base-padding/6);
		    	}
		    	.product-details__icon {
		    		float: left;
		    		color: $brand-color-2;
		    	}
			}
			&__product-desc {
				float: left;
		    	width: 25%;
		    	line-height: 1.5625rem;
    			height: 1.5625rem;
    			>div {
	    			text-overflow: ellipsis;
				    overflow: hidden;
				    white-space: nowrap;
    			}
			}
			&__status {
				float: left;
		    	width: 25%;
		    	color: $gray;
		    	line-height: 1.5625rem;
    			height: 1.5625rem;
    			text-align: right;
			}
			&__price {
				float: left;
		    	width: 14%;
		    	text-align: right;
		    	line-height: 1.5625rem;
    			height: 1.5625rem;
		    	
		    	.price__price-details {
		    		&_currency {
		    			font-size: .75rem;
		    		}
		    		&_amount {
		    			font-size: 1.125rem;
    					color: $brand-color-1;
		    		}
		    	}
			}
	    }

		&_productList{
		    @include clearfix;
  		    padding-left: 1rem;
			width : 100%;
		}
	}
	&__paycheckbox {
          position: absolute;
          left: 3%;
     }
}