.guest-house-inventory{
    border: 1px solid $gray-lightest;
	&__button{
		display: initial;
    float: right;
    padding-left: 5rem;
    padding-top: 1.5rem;
    width: 40%;
    margin-top: 1rem;
		&_bulk-update,&_search{
    background-color: $brand-color-2;
    border: none;
    border-radius: 0;
    color: $light;
    float: left;
    font-size: .875rem;
    font-weight: 700;
    line-height: inherit;
    margin-left: 2rem;
    padding: 0.25rem 0.5rem;
    width: 40%;
		}
	&_search{
		background-color: $brand-color-1;
		}
	}
	&__years,&__months,&__days{
	border-bottom: 1px solid $gray-lightest;
    border-collapse: separate;
    border-spacing: 0 10px;
    text-align: center;
    width: 100%;
		&_year{
			width: 9.09%;
		}
		&_month{
			width: 8.33%;
		}
		&_select{
			background-color: $brand-color-15;
    		color: $light;
    		font-weight: 1000;
		}
		&_label,&_data{
			height: 4rem;
    		font-weight: 900;
			border: 1px solid $gray-lightest;
		}
		&_label{
			width: 7%;
		}
		&_data{
			width: 3%;
			.data-input{
				width: 100%;
			    border: 1px solid $gray-lighting;
    			text-align: center;
    			border-radius: 6px;
				color: $gray-lightest;
			}
			.data-display{
    			text-align: center;
				color: $text-color;
			}
			.available{
			background-color:$brand-color-13;			
			}
			.not-available{
				background-color: $brand-color-9;
			}
		}
	}
	&__days{
		border-spacing: 0px;
	}	
}
.guest-house-name-msg{
	    font-size: 16px;
    margin-bottom: 2rem;
    color: $brand-color-2;
}

.identification{
        height: 2.5rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
	width:100%;
		&__available,&__not-available,&__close-out{
			    height: 2rem;
    			padding: 0.313rem;
    			width: 20%;
				float:left;
			&_symbol{
  			width: 1rem;
  			height: 1rem;
			float: left;		
			}
			&_label{
				color: $brand-color-22;
    float: left;
    width: 40%;
    height: 2rem;
    text-align: left;
    font-size: $font-size-h5;
    font-weight: 700;
    line-height: inherit;
	padding-left: 0.313rem;
			}
		}
		&__available{
			&_symbol{				
			background-color:$brand-color-13;
			}
		}
		&__not-available{
			&_symbol{				
			background-color:$brand-color-9;
			}
		}
	}